<template>
    <div id="recycle_chart"></div>
</template>
<script>
import * as Echarts from "echarts";
import { mapGetters } from 'vuex'
export default {
    inject:['baseUrl'],
    mounted() {
        this.$nextTick(this.getData)
    },
    computed:{
        ...mapGetters(['screenWasterTId','screenRegionId'])
    },
    watch:{
        'screenWasterTId':function(){
            this.getData()
        },
        'screenRegionId':function(){
            this.getData()
        }
    },
    methods: {
        getData(){
            let url = this.baseUrl + 'recycleStat'
            this.$http.post(url).then(res=>{
                this.draw(res.detail)
            })
        },
        formatKgToT(v){
            if (!v) {
                return 0
            }
            v = v / 1000
            return v > 100 ? v : v.toFixed(1)
        },
        draw(dl) {
            const dom = document.querySelector("#recycle_chart");
            const eIns = Echarts.init(dom);
            const total = dl.reduce((a,b)=>{
                return {sum:a.sum+b.sum}
            })
            const dateList = dl.map(one=>{
                let o = {
                    value: this.formatKgToT(one.sum),
                    name:one.recycle.cName,
                    percent: (one.sum/total.sum).toFixed(2)
                }
                return o
            })
            const option = {
                series: [{
                    name: "Access From",
                    type: "pie",
                    radius: ["40%", "70%"],
                    center: ["50%", "55%"],
                    label: {
                        textBorderWidth:0,
                        color: '#fff',//'gradient',
                        formatter: function(params) {
                            const data = params.data;
                            return `{a|${data.name}}\n{b|${data.value}}吨/{c|${data.percent}}%\n`;
                        },
                        rich: {
                            a: {
                                fontSize: 12,
                                lineHeight: 30
                            },
                            b: {
                                fontSize: 18
                            },
                            c: {
                                fontSize: 18
                            }
                        }
                    },
                    color: ["#5470c6","#91cc75","#fac858","#ee6666"],
                    data:dateList
                    // data: [
                    //     { value: 1048, name: "建筑垃圾", percent: "47", label: { color: "#5470c6" } },
                    //     { value: 735, name: "其他固废", percent: "47", label: { color: "#91cc75" } },
                    //     { value: 580, name: "一般工业固废", percent: "47", label: { color: "#fac858" } },
                    //     { value: 484, name: "生活垃圾", percent: "47", label: { color: "#ee6666" } }
                    // ]
                }]
            };
            eIns.setOption(option);
        }
    }
};
</script>
<style lang="scss" scoped>
#recycle_chart {
    height: 100%;
}
</style>