<template>
    <div :style="{height:vh+'px'}" class="box">
        <table>
            <thead>
                <tr>
                    <td></td>
                    <td>企业名称</td>
                    <td>产废量</td>
                    <td>去年同期</td>
                    <td>产废利用率</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(one,index) in dataList" :key="one.unitId">
                    <td>{{index+1}}</td>
                    <td>{{one.unitNa}}</td>
                    <td>{{one.yearSum | kgToT | toFixed}}吨</td>
                    <td>{{one.yearSumPre | kgToT | toFixed}}吨</td>
                    <td>{{one.yearPercent*100 | toFixed}}%</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    inject:['baseUrl'],
    filters: {
        toFixed: function(v) {
            if (v != 0) {
                return v.toFixed(2)
            } else {
                return 0
            }
        },
        kgToT: (v) => {
            if (!v) {
                return 0
            }
            return (v / 1000)
        },
    },
    data(){
        return{
            vh:null,
            dataList:[]
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.setStyle()
            this.getData()
        })
    },
    computed:{
        ...mapGetters(['screenWasterTId','screenRegionId'])
    },
    watch:{
        'screenWasterTId':function(){
            this.getData()
        },
        'screenRegionId':function(){
            this.getData()
        }
    },
    methods:{
        setStyle(){
            let h = this.$el.clientHeight
            this.vh = h /*- 20 * (window.innerWidth/1920)*/
        },
        getData(){
            let url = this.baseUrl + 'wasteUnitStat'
            let params = {
                wasteTId:this.screenWasterTId,
                regionId:this.screenRegionId
            }
            this.$http.post(url,params).then(res=>{
                this.dataList = res.detail
            })
        }
    }
};
</script>
<style lang="scss" scoped>
.box{
    height: 100%;
    overflow-y: scroll;
    margin-right: -0.3vw;
}
table {
    font-size: $screen_font_size;
    width: 100%;
    text-align: center;

    thead {
        color: #23c8ff;
        font-weight: 700;
        background-color: #1c345b;
    }

    tbody {
        tr {

            &:nth-child(even) {
                background-color: #1c345b;
            }
        }


        // tr:nth-child(even) {
        //     background-color: ;
        // }
    }

    td {
        padding: 1vh 0;
    }
}
</style>