<template>
    <div>
        <div id="screen2" v-if="showView">
            <bg></bg>
            <page-top>
                <div class="region_box" v-on:click="selectRegion">
                    {{regionNa}}
                </div>
                <div class="top_button">
                    <span v-for="one in wasteTypes" :key="one.wasteTId" :class="{'alive':one.wasteTId==wasteTId}" v-on:click="typeClick(one)">{{one.cName}}</span>
                </div>
            </page-top>
            <div class="content_view">
                <transition>
                    <component :is="current"></component>
                </transition>
            </div>
        </div>
        <img :src="screen2" style="height: 100vh;width: 100vw;" v-else />
        <region-tree ref="region" @select="addRegion"></region-tree>
    </div>
</template>
<script>
import screen2 from '@/assets/screen/screen2.png'
import pageTop from '../components/top.vue';
import bg from '../components/bg.vue'
import home from './index.bak.vue'
import regionTree from "@/components/pages/admin/common/dialog-tree/region_tree.vue";
import { mapMutations } from 'vuex'

export default {
    components: {
        pageTop,
        bg,
        home,
        regionTree
    },
    data() {
        return {
            showView: true,
            current: 'home',
            screen2,
            wasteTypes: [],
            wasteTId: '',
            regionId: '',
            regionNa: ''
        };
    },
    created() {
        this.getWasteType()
        let userInfo = this.$util.getStor("iRubbishAdminInfo") || {}
        this.regionId = userInfo.regionId
        this.regionNa = userInfo.regionNa
    },
    methods: {
        ...mapMutations(['setScreen']),
        getWasteType() {
            this.$http.post("/wasteType/listAll").then(res => {
                let { detail } = res
                this.wasteTypes = detail
                this.wasteTId = detail[0].wasteTId
                this.setScreen({ wasteTId: detail[0].wasteTId })
            })
        },
        typeClick(one) {
            this.wasteTId = one.wasteTId
            this.setScreen({ wasteTId: one.wasteTId })
        },
        selectRegion() {
            let option = {
                pid:this.regionId
            }
            this.$refs.region.init(option);
        },
        addRegion(data) {
            this.regionId = data.regionId;
            this.regionNa = data.regionNa;
            this.setScreen({regionId:data.regionId})
        },
    }
};
</script>
<style lang="scss" scoped>
html {
    overflow: hidden;

    body {
        background: transparent;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        letter-spacing: 0.07vw;

        #screen2 {
            .top_button {
                display: inline-block;
                span {
                    display: inline-block;
                    font-size: .7vw;
                    padding: .2vw;
                    border: 1px solid darken(#fff, 50%);
                    border-radius: .4vh;
                    margin: .2vh;
                    background-color: rgba(225, 225, 225, .2);
                    color: darken(#fff, 50%);
                    @extend .hand;

                    &.alive {
                        color: #fff;
                        border-color: #fff;
                    }
                }
            }
            .region_box{
                margin-right: 2vw;
                display: inline-block;
                @extend .hand;
            }
        }

        .content_view {
            height: 90vh;
        }
    }
}
</style>