<template>
    <div id="common_service">
        <div class="common_service_top">
            <count-number></count-number>
        </div>
        <div class="common_service_mid">
            <div class="common_service_left">
                <common-block class="common_service_item" title="企业产废排名">
                    <rubbish-rank></rubbish-rank>
                </common-block>
                <common-block class="common_service_item" title="行政区域产废企业数量">
                    <region-number></region-number>
                </common-block>
            </div>
            <div class="common_service_map">
                <center-map></center-map>
            </div>
            <div class="common_service_right">
                <common-block class="common_service_item" title="回收类别分布">
                    <recycle-distribute></recycle-distribute>
                </common-block>
                <common-block class="common_service_item screen_bottom" title="每月产废统计">
                    <rubbish-month></rubbish-month>
                </common-block>
            </div>
        </div>
        <div class="common_service_bottom">
            <common-block class="common_service_item" title="行政区域固废流向统计">
                <region-count></region-count>
            </common-block>
            <common-block class="common_service_item" title="分拣中心固废流向">
                <center-flow></center-flow>
            </common-block>
        </div>
    </div>
</template>

<script>
    import CommonBlock from "@/components/pages/admin/screen/block.vue";
    import CountNumber from "./CountNumber.vue";
    import CenterFlow from "./CenterFlow.vue";
    import RegionCount from "./RegionCount.vue";
    import RegionNumber from "./RegionNumber.vue";
    import RubbishRank from "./RubbishRank.vue";
    import RecycleDistribute from "./RecycleDistribute.vue";
    import RubbishMonth from "./RubbishMonth.vue";
    import CenterMap from "./CenterMap.vue";
    export default {
        components: {
            CommonBlock,
            CountNumber,
            CenterFlow,
            RegionCount,
            RegionNumber,
            RubbishRank,
            RecycleDistribute,
            RubbishMonth,
            CenterMap
        },
        data() {
            return {};
        },
        provide(){
            return{
                baseUrl:'/screen/supervision/',
            }
        },
    };
</script>
<style lang="scss" scoped="common_service">
    #common_service {
        padding: 0 1em 1em 1em;
        height: 100%;
        color: #fff;
        display: grid;
        grid-template-rows: 0.2fr 1.5fr 1fr;
        gap: 0.5rem;
        .common_service_top {
        }
        .common_service_mid {
            display: grid;
            gap: 1em;
            grid-template-columns: 1fr 2fr 1fr;
            .common_service_left {
                flex: 1;
                display: grid;
                grid-template-rows: repeat(2, 1fr);
                gap: 0.5em;
            }
            .common_service_right {
                flex: 1;
                display: grid;
                grid-template-rows: 1fr 1.5fr;
                gap: 0.5em;
            }
        }
        .common_service_bottom {
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 1rem;
        }
    }
</style>
