<template>
    <div class="flow-wrapper" v-on:click="getData">
        <div id="sankey"></div>
    </div>
</template>
<script>
import * as Echarts from "echarts";
import { mapGetters } from 'vuex'
export default {
    inject: ['baseUrl'],
    mounted() {
        this.$nextTick(() => {
            this.drawSanKey();
        });
    },
    computed: {
        ...mapGetters(['screenWasterTId', 'screenRegionId'])
    },
    watch: {
        'screenWasterTId': function() {
            this.getData()
        },
        'screenRegionId': function() {
            this.getData()
        }
    },
    methods: {
        getData() {
            let url = this.baseUrl + 'wasteLife'
            let params = {
                wasteTId: this.screenWasterTId,
                regionId: this.screenRegionId
            }
            this.$http.post(url, params).then(res => {
                this.drawSanKey(res.detail)
            })
        },
        formatterData(da) {
            let map = new Map()

            let nameList = [],
                linkList = [];
            const { unitWasteList } = da;
            if (unitWasteList && unitWasteList.length) {
                nameList.push({ name: '固废' })
                for (const unitWaste of unitWasteList) {
                    nameList.push({ name: unitWaste.tUnitNa })
                    linkList.push({
                        source: '固废',
                        target: unitWaste.tUnitNa,
                        value: unitWaste.sum
                    })
                    map.set(unitWaste.tUnitNa, unitWaste.sum);
                }
            }

            const { useList } = da;
            if (useList && useList.length) {
                nameList.push({ name: '利用' }, { name: '不可利用' })
                for (const unitWaste of useList) {
                    linkList.push({
                        source: unitWaste.unitNa,
                        target: '利用',
                        value: unitWaste.sum
                    }, {
                        source: unitWaste.unitNa,
                        target: '不可利用',
                        value: map.get(unitWaste.unitNa) - unitWaste.sum
                    })
                }
            }

            const { dealList } = da;
            if (dealList && dealList.length) {
                for (const unitWaste of dealList) {
                    nameList.push({ name: unitWaste.unitNa })
                    linkList.push({
                        source: "不可利用",
                        target: unitWaste.unitNa,
                        value: unitWaste.sum
                    })
                }
            }

            return {
                nameList,
                linkList
            }
        },
        drawSanKey(dl) {
            const dom = document.getElementById("sankey");
            const eIns = Echarts.init(dom);
            const { nameList, linkList } = this.formatterData(dl)
            const option = {
                // grid: {
                //     left: "10%",
                //     right: "4%",
                //     bottom: "15%",
                //     top: "5%"
                // },
                series: [{
                    type: "sankey",
                    layout: "none",
                    emphasis: {
                        focus: "adjacency"
                    },
                    top: "10%",
                    data: nameList,
                    links: linkList,
                    label:{
                        textBorderWidth:0,
                        color: '#fff',//'gradient',
                    },
                    lineStyle: {
                        color: 'gradient',
                        curveness: 0.8,
                        opacity: 0.4
                    }
                    // data: [{
                    //         name: "固废"
                    //     },
                    //     {
                    //         name: "A分拣中心"
                    //     },
                    //     {
                    //         name: "B分拣中心"
                    //     },
                    //     {
                    //         name: "利用"
                    //     },
                    //     {
                    //         name: "不可利用"
                    //     },
                    //     {
                    //         name: "A处置单位"
                    //     },
                    //     {
                    //         name: "B处置单位"
                    //     }
                    // ],
                    // links: [{
                    //         source: "固废",
                    //         target: "B分拣中心",
                    //         value: 3
                    //     },
                    //     {
                    //         source: "固废",
                    //         target: "A分拣中心",
                    //         value: 5
                    //     },
                    //     {
                    //         source: "A分拣中心",
                    //         target: "利用",
                    //         value: 1
                    //     },
                    //     {
                    //         source: "A分拣中心",
                    //         target: "不可利用",
                    //         value: 4
                    //     },
                    //     {
                    //         source: "B分拣中心",
                    //         target: "利用",
                    //         value: 1
                    //     },
                    //     {
                    //         source: "B分拣中心",
                    //         target: "不可利用",
                    //         value: 2
                    //     },
                    //     {
                    //         source: "不可利用",
                    //         target: "A处置单位",
                    //         value: 1
                    //     },
                    //     {
                    //         source: "不可利用",
                    //         target: "B处置单位",
                    //         value: 2
                    //     }
                    // ]
                }]
            };
            eIns.setOption(option);
        }
    }
};
</script>
<style lang="scss" scoped>
.flow-wrapper {
    height: 100%;

    #sankey {
        height: 100%;
    }
}
</style>