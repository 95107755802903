<template>
  <div class="home_halo" :style="boxStyle">
    <canvas class="canvas_box" ref="canvas"></canvas>
  </div>
</template>
<script type="text/javascript">
export default {
  data() {
    return {
      canvas: null,
      context: null,
    }
  },
  props:{
    zIndex:{
      type:Number,
    }
  },
  created() {
    this.$nextTick(() => {
      this.initCanvas()
    })
  },
  computed:{
    boxStyle:function(){
      let obj = {}
      if(this.zIndex!==undefined){
        obj.zIndex=this.zIndex
      }
      return obj
    },
    isHome:function(){
      let route = this.$route
      return route.name=='home'
    }
  },
  methods: {
    initCanvas() {
      this.canvas = this.$refs.canvas
      this.context = this.canvas.getContext('2d')
      this.draw()
    },
    draw() {
      this.drawHalo()
    },
    drawHalo() {
      const point = {x:this.canvas.width/2,y:0}
      const r = this.isHome?this.canvas.height/2:this.canvas.height/2

      let grd = this.context.createRadialGradient(point.x,point.y,0,point.x,point.y,r)
      grd.addColorStop(0,'rgb(76,201,255,.2)')
      grd.addColorStop(1,'rgb(76,201,255,.0)')

      let sAngle = Math.PI * 0.04,
        eAngle = Math.PI * 0.96
      this.context.save()
      this.context.arc(this.canvas.width / 2, 0, r, sAngle, eAngle)
      this.context.lineTo(this.canvas.width / 2, 0)
      this.context.fillStyle = grd
      this.context.shadowBlur = 20
      this.context.shadowClor = 'rgb(76,201,255,.2)'
      this.context.closePath()
      this.context.fill()
      this.context.restore()
    }
  }
}
</script>
<style type="text/css" lang="scss" scoped="home_halo">
.home_halo {
  width: 100%;
  height: 100%;

  .canvas_box {
    width: 100%;
    height: 100%;
  }
}
</style>