<template>
  <div class="screen_bg">
    <halo></halo>
    <img class="bottom_img" :src="bgBottom" :class="{'opacity':!isHome}">
  </div>
</template>
<script type="text/javascript">
import bgBottom from '@/assets/screen/bg_bottom.svg'
import halo from './bg.halo.vue'
export default{
  components:{
    halo
  },
  data(){
    return{
      bgBottom
    }
  },
  computed:{
  }
}
</script>
<style type="text/css" lang="scss">
.screen_bg{
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background: #021033;
  z-index: -10;
  .bottom_img{
    position:absolute;
    bottom:0;
    left: 0;
    right: 0;
  }
  img{
    &.opacity{
      opacity: .1
    }
  }
}
</style>