<template>
    <div class="rubbish_unit">
        <div class="rubbish_unit_top">
            <div class="count_item">
                <span>产废企业</span>
                <br />
                <span>
                    <span class="digital_num">{{data.unit0||0}}</span>
                    <span>个</span>
                </span>
            </div>
            <cross-line></cross-line>
            <div class="count_item">
                <span>环服公司</span>
                <br />
                <span>
                    <span class="digital_num">{{data.countOrg||0}}</span>
                    <span>个</span>
                </span>
            </div>
            <cross-line></cross-line>
            <div class="count_item">
                <span>分拣中心</span>
                <br />
                <span>
                    <span class="digital_num">{{data.unit3}}</span>
                    <span>个</span>
                </span>
            </div>
            <cross-line></cross-line>
            <div class="count_item">
                <span>运输车辆</span>
                <br />
                <span>
                    <span class="digital_num">{{data.vehicle||0}}</span>
                    <span>辆</span>
                </span>
            </div>
            <cross-line></cross-line>
            <div class="count_item">
                <span>运输司机</span>
                <br />
                <span>
                    <span class="digital_num">{{data.driver||0}}</span>
                    <span>人</span>
                </span>
            </div>
            <cross-line></cross-line>
            <div class="count_item">
                <span>产废接收量</span>
                <br />
                <span>
                    <span class="digital_num">{{data.wastes||0 | kgToT | toFixed}}</span>
                    <span>吨</span>
                </span>
            </div>
            <cross-line></cross-line>
            <div class="count_item">
                <span>回收利用量</span>
                <br />
                <span>
                    <span class="digital_num">{{data.stockOut||0 | kgToT | toFixed}}</span>
                    <span>吨</span>
                </span>
            </div>
            <cross-line></cross-line>
            <div class="count_item">
                <span>处置量</span>
                <br />
                <span>
                    <span class="digital_num">{{data.deal||0 | kgToT | toFixed}}</span>
                    <span>吨</span>
                </span>
            </div>
            <cross-line></cross-line>
            <div class="count_item">
                <span>回收利用率</span>
                <br />
                <span>
                    <span class="digital_num">{{data.percent*100||0 |toFixed}}</span>
                    <span>%</span>
                </span>
            </div>
            <cross-line></cross-line>
            <div class="count_item">
                <span>节能减排</span>
                <br />
                <span>
                    <span class="digital_num">30009</span>
                    <span>kg</span>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import CrossLine from "@/components/pages/admin/screen/CrossLine.vue";
import { mapGetters } from 'vuex'
export default {
    inject:['baseUrl'],
    filters: {
        toFixed: function(v) {
            if (v != 0) {
                return v.toFixed(2)
            } else {
                return 0
            }
        },
        kgToT: (v) => {
            if (!v) {
                return 0
            }
            return (v / 1000)
        },
    },
    data() {
        return {
            industry: "",
            industryList: [],
            data:{},
        };
    },
    computed:{
        ...mapGetters(['screenWasterTId','screenRegionId'])
    },
    mounted() {
        this.initDicts();
        this.getData()
    },
    watch:{
        'screenWasterTId':function(){
            this.getData()
        },
        'screenRegionId':function(){
            this.getData()
        }
    },
    methods: {
        getData(){
            let url = this.baseUrl + 'countMsgCom'
            let params = {
                wasteTId:this.screenWasterTId,
                regionId:this.screenRegionId
            }
            this.$http.post(url,params).then(res=>{
                this.data = res.detail
            })
        },
        initDicts() {
            this.$http.post("/dict/getDictValuesByDictId", "dictindustry").then(res => {
                this.industryList = res.detail;
            });
        }
    },
    components: { CrossLine }
};
</script>
<style lang="scss" scoped>
.rubbish_unit {
    font-size: $screen_font_size * 1.4;
    padding: 10px;
    text-align: center;

    .rubbish_unit_top {
        display: flex;
        justify-content: space-around;

        .count_item {
            .digital_num {
                font-family: digital;
                font-weight: 700;
                font-size: $screen_font_size * 3;
                color: #24c8ff;
            }
        }
    }
}
</style>