<template>
    <div id="regionCount"></div>
</template>
<script>
import * as Echarts from "echarts";
import { mapGetters } from 'vuex'
export default {
    inject: ['baseUrl'],
    mounted() {
        this.$nextTick(() => {
            this.getData();
            this.drawChart();
        });
    },
    computed: {
        ...mapGetters(['screenWasterTId', 'screenRegionId'])
    },
    watch: {
        'screenWasterTId': function() {
            this.getData()
        },
        'screenRegionId': function() {
            this.getData()
        }
    },
    methods: {
        getData() {
            let url = this.baseUrl + 'flowRegion'
            let params = {
                wasteTId: this.screenWasterTId,
                regionId: this.screenRegionId
            }
            this.$http.post(url, params).then(res => {
                console.log(res.detail)
                this.drawChart(res.detail)
            })
        },
        drawChart(dl) {
            const dom = document.getElementById("regionCount");
            const eIns = Echarts.init(dom);
            const xl = dl.map(one => one.regionNa)
            const del = dl.map(one => one.sumDeal)
            const dea = dl.map(one => one.percent)
            const rl = dl.map(one => one.sumStockIn)
            const option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    }
                },
                legend: {
                    right: '50',
                    textStyle:{
                        color:'#fff'
                    }
                },
                grid: {
                    left: "80",
                    right: "50",
                    bottom: "40",
                    top: "40"
                },
                xAxis: {
                    type: "category",
                    data: xl,
                    axisLabel:{
                        color:'#fff'
                    }
                },
                yAxis: [{
                    type: "value",
                    show: true,
                    axisLabel:{
                        color:'#fff'
                    },
                    axisLine: {
                        show: true
                    },
                    axisTick: {
                        show: true
                    },
                    splitLine: {
                        show: false
                    },
                    splitArea: {
                        show: true,
                        areaStyle: {
                            color: ["#1c345b", "rgba(0,0,0,0)"]
                        }
                    },
                },{
                    type:'value',
                    axisLabel:{
                        color:'#fff'
                    },
                    splitLine: {
                        show: false
                    },
                }],
                series: [{
                        name: "回收利用",
                        type: "bar",
                        data: rl
                    },
                    {
                        name: "处置量",
                        type: "bar",
                        data: del
                    },
                    {
                        name: "处置比例",
                        type: "line",
                        data: dea,
                        yAxisIndex: 1
                    }
                ]
            };
            eIns.setOption(option);
        }
    }
};
</script>
<style scoped>
#regionCount {
    height: 100%;
}
</style>