<template>
    <div class="map-wrapper">
        <el-row>
            <el-col :span="24">
                <div class="map-tip">
                    热力图代表各辖区分拣回收利用率高低
                    <br />
                    柱状图代表各辖区产废接收量高低
                    <br />
                </div>
                <div ref="map" class="map"></div>
                <div style="text-align:center;" class="back_block">
                    <el-button @click="back" type="primary" size="small" v-if="regionId !== adcode">返回上一层</el-button>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import * as Echarts from "echarts";
// import China from "@/assets/map/china.json";
// import { throttle } from "lodash";
export default {
    props: {
        dataList: {
            type: Array,
            default: null,
        },
        regionId: {
            type: String,
            default: '100000'
        },
        regionNa: {
            type: String,
            default: 'china'
        }
    },
    watch: {
        dataList() {
            this.$nextTick(async () => {
                this.draw()
            });
        },
        eIns() {
            this.eIns.off('click')
            this.eIns.on('click', async (params) => {
                console.log(params, 'params')
                try {
                    const { data: { value: adcode }, name } = params;
                    this.preAdcodes.push(this.adcode)
                    this.preRegionNas.push(this.rNa)
                    this.adcode = adcode
                    this.rNa = name;
                    const status = await this.loadMap().catch(() => false);
                    if (status) {
                        this.$nextTick(() => {
                            this.eIns.clear()
                            this.eIns.showLoading({ text: 'Loading' })
                            this.$emit('update', adcode)
                        })
                    } else {
                        this.adcode = this.preAdcodes.pop()
                        this.rNa = this.preRegionNas.pop()
                    }
                } catch (e) {
                    console.log(e)
                }

            })
        }
    },
    created() {
        this.adcode = this.regionId
        this.rNa = /100000/g.test(this.regionId) ? 'china' : this.regionNa
        window.addEventListener('resize', this.handleResize)
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    async mounted() {
        await this.loadMap()
    },
    data() {
        return {
            eIns: null,
            timeoutId: null,
            adcode: null,
            rNa: '',
            preAdcodes: [],
            preRegionNas: []
        };
    },
    methods: {
        async loadMap() {
            // return fetch(`https://geo.datav.aliyun.com/areas_v3/bound/${this.adcode}_full.json`)
            return fetch(`/geo/areas_v3/bound/${this.adcode}_full.json`)
                .then(res => {
                    console.log(res.ok, 'ok?')
                    return res.ok ?
                        res.json() :
                        Promise.reject(null)
                })
                .then(data => {
                    Echarts.registerMap(this.rNa, data);
                    return true;
                })
        },
        back() {
            if (this.preAdcodes.length) {
                this.adcode = this.preAdcodes.pop()
                this.rNa = this.preRegionNas.pop()
                this.eIns.clear()
                this.eIns.showLoading({ text: 'Loading' })
                this.$emit('update', this.adcode)
            }
        },
        handleResize() {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId)
                this.timeoutId = null
            }
            this.timeoutId = setTimeout(() => {
                this.$nextTick(() => {
                    this.eIns.resize()
                    this.renderEachCity()
                    this.bar.resize()
                })
            }, 500);
        },
        draw() {
            this.drawMap();
        },
        renderEachCity() {
            const geoCoordMap = this.dataList.reduce((prev, cur) => {
                prev[cur.region.regionNa] = [cur.region.lng, cur.region.lat];
                return prev;
            }, {});
            // const geoCoordMap = {
            //     上海: [121.48, 31.22],
            //     福建: [118.1, 27.46],
            //     浙江: [119.96, 29.86],
            //     广东: [113.23, 24.16],
            // };
            const rawData = this.dataList.reduce((prev, cur) => {
                return (prev.push([cur.region.regionNa, cur.sumWasteToT || 0, cur.percent]), prev);
            }, [])

            let tmpOption = {
                xAxis: [],
                yAxis: [],
                grid: [],
                series: [],
                tooltip: {
                    trigger: "item",
                    axisPointer: {
                        type: "none"
                    }
                }
            };
            const _this = this;
            Echarts.util.each(rawData, function(dataItem, idx) {
                var geoCoord = geoCoordMap[dataItem[0]];
                var coord = _this.eIns.convertToPixel("geo", geoCoord);
                idx += "";

                tmpOption.xAxis.push({
                    id: idx,
                    gridId: idx,
                    type: "category",
                    name: dataItem[0],
                    nameLocation: "middle",
                    nameGap: 3,
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    axisLine: {
                        onZero: false,
                        lineStyle: {
                            color: "transparent"
                        }
                    },
                    // data: xAxisCategory,
                    // data: ["数据A", "数据B", "数据C"],
                    z: 100
                });
                tmpOption.yAxis.push({
                    id: idx,
                    gridId: idx,
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "#1C70B6"
                        }
                    },
                    z: 100
                });
                tmpOption.grid.push({
                    id: idx,
                    width: 10,
                    height: 40,
                    left: coord[0] - 7,
                    top: coord[1] - 40,
                    z: 100
                });
                tmpOption.series.push({
                    id: idx,
                    type: "bar",
                    // type: "bar3D",
                    xAxisId: idx,
                    yAxisId: idx,
                    barGap: 0,
                    barCategoryGap: 0,
                    // data: inflationData,
                    data: [dataItem],
                    z: 100,
                    itemStyle: {
                        normal: {
                            color: function(params) {
                                // 柱状图每根柱子颜色
                                // var colorList = ["#F75D5D", "#59ED4F", "#4C91E7"];
                                // return colorList[params.dataIndex];
                                return params.color
                            }
                        }
                    }
                });
            });
            this.eIns.setOption(tmpOption);
            this.eIns.hideLoading()
        },
        drawMap() {
            const heatmapData = [];
            const mapData = [];
            for (const data of this.dataList) {
                mapData.push({
                    name: data.region.regionNa,
                    value: data.region.regionId
                })
                heatmapData.push({
                    value: [
                        data.region.lat,
                        data.region.lng,
                        data.sumWasteToT
                    ]
                })
            }

            const dom = this.$refs.map;
            this.eIns = Echarts.init(dom);

            let heapDataMax = Math.max(...this.dataList.map(each => each.sumWasteToT)) || 100;

            const option = {
                // 需要全局设置 tooltip trigger 为 'item' 后，effectScatter series 的 tooltip 才会显示。
                tooltip: {
                    trigger: "item",
                    formatter: function(data) {
                        console.log(data, '--')
                        return `
                            地区: ${data.value[0]}<br/>
                            产废接收量: ${data.value[1]}吨<br/>
                            回收利用率: ${data.value[2] * 100}%
                            `
                    }
                },
                backgroundColor: "transparent", //画布背景颜色
                visualMap: [{
                    show: false,
                    max: heapDataMax,
                    min: 0,
                    calculable: true,
                    inRange: {
                        color: ["#0033FF", "#FFFF00", "#FF3333"]
                    },
                    dimension: 1,
                }],
                // 底图
                geo: {
                    show: true,
                    map: this.rNa,
                    geoIndex: 0,
                    zoom: 1,
                    label: {
                        normal: {
                            show: false
                        },
                        emphasis: {
                            show: true
                        }
                    },
                    roam: false,
                    tooltip: {
                        show: false
                    },
                    itemStyle: {
                        normal: {
                            areaColor: "rgba(0, 0, 0, 0)",
                            borderWidth: 3, //设置外层边框
                            borderColor: "#4786E3",
                            shadowColor: "rgba(63, 236, 209, 1)"
                        }
                    }
                },
                // 深色背景图层
                series: [{
                        type: "map",
                        map: this.rNa,
                        zoom: 1,
                        geoIndex: 1,
                        // aspectScale: 0.75, // 长宽比, 默认值 0.75
                        showLegendSymbol: false, // 存在legend时显示
                        label: {
                            normal: {
                                show: false
                            },
                            emphasis: {
                                show: true,
                                textStyle: {
                                    color: "#fff"
                                }
                            }
                        },
                        roam: false,
                        tooltip: {
                            show: false,
                        },
                        itemStyle: {
                            normal: {
                                areaColor: "transparent",
                                borderColor: "#4786E3",
                                borderWidth: 1
                            },
                            emphasis: {
                                areaColor: "#21AEF8"
                            }
                        },
                        data: mapData
                    },
                    {
                        name: "热力图",
                        type: "heatmap",
                        coordinateSystem: "geo",
                        data: heatmapData
                    }
                ]
            };
            this.eIns.showLoading({ text: 'Loading' })
            this.eIns.setOption(option);
            this.renderEachCity();

            // setTimeout(this.renderEachCity);
            // this.eIns.on("geoRoam", throttle(this.renderEachCity));
        },
    }
};
</script>
<style lang="scss" scoped>
.map-wrapper {
    height: 100%;

    .el-row,
    .el-col,
    .map,
    .rubbish-number {
        height: 100%;
    }

    .el-col {
        position: relative;
    }

    .map-tip {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 16px;
        font-weight: bold;
        color: #4786e3;
    }

    .back_block {
        position: absolute;
        right: 0;
        top: 0;
    }
}
</style>