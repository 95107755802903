<template>
    <div class="common_block">
        <div class="common_block_title" v-if="title">
            <span>{{ title }}</span>
        </div>
        <div class="common_block_content">
            <slot></slot>
        </div>
    </div>
</template>
<script type="text/javascript">
    export default {
        name: "common-block",
        props: {
            title: {
                type: String,
                default: ""
            }
        }
    };
</script>
<style type="text/css" lang="scss" scoped="common_block">
    @function background-opacity($opacity: 1) {
        @return rgba(4, 82, 154, $opacity);
    }
    .common_block {
        height: 100%;
        width: 100%;
        overflow: hidden;
        background: background-opacity(0.23);
        display: flex;
        flex-direction: column;

        .common_block_title {
            background: linear-gradient(to right, background-opacity(), background-opacity(0));
            font-size: 1.8vh;
            letter-spacing: 0.3vh;
            padding: 0.7vh 1vw;
            color: #fff;
        }
        .common_block_content {
            flex: 1;
            position: relative;
        }
    }
</style>
