<template>
    <div class="screen_top">
        <div class="top_bg_box">
            <img :src="topBg" />
        </div>
        <div class="logo_box">
            <img :src="logo" />
            <h2>智慧固废服务平台</h2>
        </div>
        <div class="time_box">
            <div class="time_box_left">{{ myDate.time }}</div>
            <div class="time_box_right">
                <p>{{ myDate.date }}</p>
                <p>星期{{ myDate.day }}</p>
            </div>
        </div>
        <div class="right_box">
            <slot></slot>
        </div>
    </div>
</template>
<script type="text/javascript">
import topBg from "@/assets/screen/top_bg.png";
// import logo from '@/assets/top_banner.svg'
import logo from "@/assets/e_logo.png";
// import logo from "@/assets/top_banner.svg";
const dayList = ["日", "一", "二", "三", "四", "五", "六"];

export default {
    data() {
        return {
            topBg,
            logo,
            myDate: {
                date: new Date().Format("yyyy-MM-dd"),
                day: dayList[new Date().getDay()],
                time: new Date().Format("hh:mm")
            },
            interval: null
        };
    },
    computed: {
        logoName: function() {
            let route = this.$route;
            return route.name || "home";
        }
    },
    created() {
        this.setIntervalFun();
    },
    activated() {
        this.setIntervalFun();
    },
    deactivated() {
        clearInterval(this.interval);
    },
    methods: {
        setIntervalFun() {
            clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.myDate = {
                    date: new Date().Format("yyyy-MM-dd"),
                    day: dayList[new Date().getDay()],
                    time: new Date().Format("hh:mm")
                };
            }, 1000);
        }
    }
};
</script>
<style type="text/css" lang="scss" scoped="screen_top">
.screen_top {
    height: 10vh;
    color: #fff;
    font-size: 1vw;
    text-align: center;
    line-height: 10vh;
    position: relative;

    .top_bg_box {
        height: 28vh;
        position: absolute;
        width: 100%;
        z-index: -1;

        img {
            height: 100%;
        }
    }

    .logo_box {
        height: 70%;
        position: relative;
        text-align: center;

        img {
            height: 100%;
            vertical-align: middle;
            margin-right: 15px;
        }

        h2 {
            display: inline-block;
        }
    }

    .time_box {
        position: absolute;
        line-height: normal;
        top: 0.2vh;
        right: 9vw;
        display: flex;
        justify-content: center;

        &_left {
            margin-right: 0.5vw;
            color: #fff;
            font-size: 1.2vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &_right {
            text-align: left;
            color: #dcdcdc;
            font-size: 0.8vw;
        }
    }

    .right_box{
        position: absolute;
        position: absolute;
        line-height: normal;
        top: 0.2vh;
        left: 9vw;
    }
}
</style>