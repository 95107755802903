<template>
    <div class="map_wrapper" v-loading="loading">
        <my-map :dataList = "dataList" :regionId="showRegionId" v-on:update="updateFun"></my-map>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import myMap from './cMap.vue'
export default {
    inject: ['baseUrl'],
    computed: {
        ...mapGetters(['screenWasterTId', 'screenRegionId'])
    },
    data(){
        return{
            dataList:[],
            showRegionId:'100000',
            loading:false
        }
    },
    watch:{
        'screenRegionId':function(val){
            if (!val) {
                this.showRegionId = '10000'
            }else{
                this.showRegionId = val.toString()
            }
            this.getData()
        }
    },
    components:{
        myMap
    },
    mounted() {
        this.getData()
    },
    methods: {
        updateFun(data){
            this.showRegionId = data.toString()
            this.getData()
        },
        getData(){
            let url = this.baseUrl + 'unitInfoWasteRegion'
            let params={
                regionId: this.showRegionId
            }
            this.loading = true
            this.$http.post(url,params).then(res=>{
                this.dataList = res.detail
                res.detail.forEach(one=>{
                    one.sumWasteToT = (one.sumWaste / 1000).toFixed(1)
                })
            }).finally(()=>{
                console.log("finally")
                this.loading = false
            })
        },
    }
};
</script>
<style lang="scss" scoped>
.map_wrapper {
    height: 100%;
    position: relative;
}
</style>