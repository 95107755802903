import { render, staticRenderFns } from "./RegionNumber.vue?vue&type=template&id=6edda111&scoped=true&"
import script from "./RegionNumber.vue?vue&type=script&lang=js&"
export * from "./RegionNumber.vue?vue&type=script&lang=js&"
import style0 from "./RegionNumber.vue?vue&type=style&index=0&id=6edda111&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6edda111",
  null
  
)

export default component.exports