<template>
    <div class="region-wrapper" v-on:click="getData">
        <h4>总量</h4>
        <div id="regionNumber"></div>
    </div>
</template>
<script>
import * as Echarts from "echarts";
import { mapGetters } from 'vuex'
export default {
    inject: ['baseUrl'],
    mounted() {
        this.getData();
    },
    computed: {
        ...mapGetters(['screenWasterTId', 'screenRegionId'])
    },
    watch: {
        'screenWasterTId': function() {
            this.getData()
        },
        'screenRegionId': function() {
            this.getData()
        }
    },
    methods: {
        getData() {
            let url = this.baseUrl + 'unitCountRegion'
            let params = {
                wasteTId: this.screenWasterTId,
                regionId: this.screenRegionId
            }
            this.$http.post(url, params).then(res => {
                console.log(res.detail)
                this.draw(res.detail)
            })
        },
        draw(dl) {
            const dom = document.querySelector("#regionNumber");
            const eIns = Echarts.init(dom);
            dl.sort((a, b) => {
                return a.count - b.count
            })
            let p = dl.slice(0, -6)
            let a = dl.slice(-6)
            let obj = {
                regionNa: '其它',
                count: (p.reduce((b, c) => {
                    return { count: b.count + c.count }
                })).count
            }
            a.unshift(obj)
            const xl = a.map(one => one.regionNa)
            const vl = a.map(one => one.count)
            const option = {
                grid: {
                    top: "5%",
                    left: "120",
                    bottom: "10%"
                },
                xAxis: {
                    type: "value",
                    boundaryGap: [0, 0.01],
                    show: false,
                    axisLabel: {
                        color: '#fff'
                    }
                },
                yAxis: [{
                        type: "category",
                        position: "left",
                        axisLabel: {
                            color: '#fff'
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        data: xl
                    },
                    {
                        type: "category",
                        position: "right",
                        axisLabel: {
                            color: '#fff'
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        nameTextStyle: {
                            fontFamily: "digital"
                        },
                        data: vl
                    }
                ],
                series: [{
                    name: "2011",
                    type: "bar",
                    data: vl
                }]
            };
            eIns.setOption(option);
        }
    }
};
</script>
<style lang="scss" scoped>
.region-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    h4 {
        text-align: right;
        padding-right: 10px;
    }

    #regionNumber {
        flex: 1;
    }
}
</style>